import React from 'react'
import { graphql } from 'gatsby'
import initDashTree from './dashutils/initDashTree.js'
import getCardNode from './dashutils/getCardNode.js'
import addCardNode from './dashutils/addCardNode.js'
import * as dashNodeComponents from './dashNodeComponents'
import EntityInfoComponent from './cardComponents/EntityInfoComponent'
import ListLinksComponent from './cardComponents/ListLinksComponent'
import OccupationInfoComponent from './cardComponents/OccupationInfoComponent'
import ClassInfoComponent from './cardComponents/ClassInfoComponent'
import ClassLiteralsComponent from './cardComponents/ClassLiteralsComponent'
import TodoComponent from './cardComponents/TodoComponent.js'
import ObjectMermaidComponent from './cardComponents/ObjectMermaidComponent.js'
import ObjectPropertiesComponent from './cardComponents/ObjectPropertiesComponent.js'
import ObjectMoreSpecificTypesComponent from './cardComponents/ObjectMoreSpecificTypesComponent.js'
import ObjectValueOfPropertiesComponent from './cardComponents/ObjectValueOfPropertiesComponent.js'
import Seo from '../seo.js'
import ClassRFComponent from './cardComponents/ClassRFComponent.js'
import DashHeart from '../dashibiden/DashHeart.js'
import getEntityLabel from '../../EntityUtils/getEntityLabel.js'


const components = {
  // generic dashTree properties
  ...dashNodeComponents,
  // generic components
  'EntityInfoComponent': EntityInfoComponent,
  'ListLinksComponent': ListLinksComponent,
  // for esco :
  'OccupationInfoComponent': OccupationInfoComponent,

  // utils
  'TodoComponent': TodoComponent,

  // for rdfs classes
  'ClassInfoComponent': ClassInfoComponent,
  'ClassLiteralsComponent': ClassLiteralsComponent,
  'ObjectMermaidComponent': ObjectMermaidComponent,
  'ClassRFComponent': ClassRFComponent,
  'ObjectPropertiesComponent': ObjectPropertiesComponent,
  'ObjectMoreSpecificTypesComponent': ObjectMoreSpecificTypesComponent,
  'ObjectValueOfPropertiesComponent': ObjectValueOfPropertiesComponent,

}

// @TODO : label, example, status can be removed from this query no ?
export const query = graphql`
query RdfsClassQuery6($id: String) {
  entity: rdfsClass(id: {eq: $id}) {
    id
    label {
      _language
      _value
    }
    example {
      _language
      _value
    }
    status
  }
}
`
// @TODO: name as DashLayoutClass
export default function DashTreeLayout6(props) {
  
  const dashTree = initDashTree()

  // explanation: data comme from the page static query and pageData from the fragments embedded in the component
  // create a single object from this 2 sources in "page data"
  const { entity } = props.data
  const { pageData } = props.pageContext
  pageData.entity = entity

  const entityLabel = getEntityLabel(entity)
  const classLitCard = getCardNode(pageData, 'ClassLiteralsComponent',
    {
      cardName: entityLabel,
      columnSize: 4,
      showContribution: true,
      // showEditOnto: true,
    }
  )
  addCardNode(dashTree, classLitCard, 0, 0)

  const reactFlow = getCardNode(pageData, 'ClassRFComponent', { cardName: 'Représentation Entity Graph', columnSize: 8, })
  addCardNode(dashTree, reactFlow, 0, 1)

  const objectProps = getCardNode(pageData, 'ObjectPropertiesComponent', { cardName: 'Propriétés de l\'objet' })
  addCardNode(dashTree, objectProps, 1, 0)

  const moreSpecific = getCardNode(pageData, 'ObjectMoreSpecificTypesComponent', { cardName: 'Classes plus spécifiques', columnSize: 4, })
  addCardNode(dashTree, moreSpecific, 1, 1)

  const valueOfProps = getCardNode(pageData, 'ObjectValueOfPropertiesComponent', { cardName: 'Propriétés ayant pour valeur une instance de cette classe', columnSize: 8, })
  addCardNode(dashTree, valueOfProps, 2, 0)

  const todoExamples = { contentText: '@TODO: Affichage des exemples et définition du processus de saisie de ces examples', ...pageData }
  const todoE = getCardNode(todoExamples, 'TodoComponent', { cardName: 'Examples', columnSize: 4, })
  addCardNode(dashTree, todoE, 2, 1)

  return (
    <>
      <Seo title={entityLabel} />
      <DashHeart dashTree={dashTree} components={components} />
    </>
  );
}
